<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <a href="#"> <i class="uil-home-alt"></i> </a>
              </li>
              <li>Reklam Alanı Yönetimi</li>
              <li>Reklamı Güncelle</li>
            </ul>
          </nav>
        </div>

        <div class="card" v-if="is_loading">
          <default-loading></default-loading>
        </div>
        <template v-else>
        <template v-if="errorMessages">
          <div v-for="(error,index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color:red;">
              <i class="uil-exclamation-triangle"></i> {{ error }} </p>
          </div>
          </template>
          <div v-if="successMessage" class="uk-alert-success" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color:green;">
              <i class="uil-exclamation-triangle"></i> {{ successMessage }} </p>
          </div>
        <div class="card mt-4">
          <div class="card-body">
            <h4><i class="uil-question-circle"></i> Reklamı Güncelle</h4>
            <hr />
            <div class="row">
              <div class="col-xl-9 m-auto">
                <form action="">
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="ad_title"
                      >Başlık <span class="required">*</span></label
                    >
                    <div class="col-md-9">
                      <input
                        type="text"
                        class="form-control"
                        id="ad_title"
                        name="title"
                        v-model="item.name"
                        placeholder="Başlık Giriniz"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="form-group row mb-3 align-items-center">
                    <label class="col-md-3 col-form-label" for="ad_title"
                      >Durum <span class="required">*</span></label
                    >
                    <div class="col-md-3">
                      <div class="d-flex align-items-center">
                        <input
                          id="status_id"
                          type="checkbox"
                          class="mb-0"
                          v-model="item.status_id"
                        />
                        <label class="ml-2 mb-0" style="line-height: 1em" for="status_id">{{
                          item.status_id ? $t("general.active") : $t("general.passive")
                        }}</label>
                      </div>
                    </div>
                     <label class="col-md-3 col-form-label" for="ad_title"
                      >Yeni Sekme Aç <span class="required">*</span></label
                    >
                    <div class="col-md-3">
                      <div class="d-flex align-items-center">
                        <input
                          id="is_new_tab"
                          type="checkbox"
                          class="mb-0"
                          v-model="item.is_new_tab"
                        />
                        <label class="ml-2 mb-0" style="line-height: 1em" for="is_new_tab">{{
                          item.is_new_tab ? $t("general.yes") : $t("general.no")
                        }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label
                      class="col-md-3 col-form-label"
                      for="short_description"
                      >Açıklama <span class="required">*</span></label
                    >
                    <div class="col-md-9">
                      <textarea
                        v-model="item.text"
                        id="short_description"
                        class="form-control"
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="ad_title"
                      >Bağlantı <span class="required">*</span></label
                    >
                    <div class="col-md-9">
                      <input
                        type="text"
                        class="form-control"
                        id="ad_title"
                        name="title"
                        v-model="item.url"
                        placeholder="https://www.example.com"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="ad_title"
                      >Düğme Metni <span class="required">*</span></label
                    >
                    <div class="col-md-9">
                      <input
                        type="text"
                        class="form-control"
                        id="ad_title"
                        name="title"
                        v-model="item.button_text"
                        placeholder="Düğme Metni Giriniz"
                      />
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="point"
                      >Reklam Görseli <span class="required">*</span></label
                    >
                    <div class="col-md-9">
                      <div uk-alert>
                        <a class="uk-alert-close" uk-close></a>
                        <h5><i class="uil-comment-info-alt"></i> BİLGİ</h5>
                        <p>
                          Bu bölüme <b>.jpg, .png, .gif</b> formatlarındaki bir
                          görsel dokümanı yükleyebilirsiniz. Görselin boyutu <b>1920x410</b> ölçülerinde olmalıdır.
                        </p>
                      </div>
                      <div class="uploadOuter">
                        <label for="uploadFile" class="btn btn-info"
                          ><i class="uil-upload"></i> Yükle</label
                        >
                        <strong>VEYA</strong>
                        <span class="dragBox">
                          <i class="icon-feather-file-plus"></i>
                          Dokümanı Buraya Sürükleyin
                          <input
                            type="file"
                            accept="image/*"
                            draggable="true"
                            @dragover.prevent
                            @dragenter="drag"
                            @dragleave="drop"
                            id="uploadFile"
                            @change="dropped"
                          />
                        </span>
                      </div>
                    <div class="position-relative d-flex justify-center justify-content-center">
                      <img style="height: 190px;" class="rounded img-fluid logoarea" :src="item.image_link" alt="">
                    </div>
                    </div>
                  </div>
                  <div class="uk-card-footer float-right pb-0 pr-0">
                    <button
                      class="uk-button uk-button-primary small"
                      type="button"
                      @click="saveForm"
                    >
                      <i class="icon-feather-arrow-right"></i> {{$t('general.save')}}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import moment from "moment";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import module, {
  BASE_URL,
  ITEM,
  GET_ITEM,
  MODULE_NAME,
  UPDATE_ITEM,
  ERROR,
} from "@/core/services/store/advertisement.module";

export default {
  name: "AdvertisementEdit",
  components: { DefaultLoading },
  data() {
    return {
      errorMessages:[],
      successMessage:null,
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    item: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) {},
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    },
  methods: {
    getItem(){
       let self = this
       this.$store.dispatch(MODULE_NAME + "/" + GET_ITEM, {
        url: BASE_URL+"/"+this.$route.params.id,
      });
    },
    saveForm(){
      let form = this.item;
      let formData = new FormData()
      this.errorMessages = [];
      this.is_loading = true
      if (form.name.length < 2) {
        this.errorMessages.push(this.$t("advertisement.required.name"));
        this.scrollTop()
      this.is_loading = false
        return false;
      }
      if (form.text.length < 2) {
        this.errorMessages.push(this.$t("advertisement.invalid.description"));
        this.scrollTop()
      this.is_loading = false
        return false;
      }
      if (form.url.length < 2) {
        this.errorMessages.push(this.$t("advertisement.required.url"));
        this.scrollTop()
        return false;
      }
      if (form.url ==  null  || !this.validateURL(form.url)) {
        this.errorMessages.push(this.$t("advertisement.invalid_url"));
        this.scrollTop()
      this.is_loading = false
        return false;
      }
      if (form.button_text.length < 2) {
        this.errorMessages.push(this.$t("advertisement.required.button_text"));
        this.scrollTop()
      this.is_loading = false
        return false;
      }

      if(!form.url.includes('http')) form.url = 'https://'+form.url

      formData.append('name',form.name)
      formData.append('text',form.text)
      formData.append('status_id',form.status_id == true ? 1 : 0)
      formData.append('is_new_tab',form.is_new_tab == true ? 1 : 0)
      formData.append('url',form.url)
      formData.append('button_text',form.button_text)
      formData.append('photo_link', form.photo_link)
      store.dispatch(MODULE_NAME + '/' + UPDATE_ITEM, {
        url: BASE_URL+'/update/'+this.$route.params.id,
        contents: formData
      }).then(() => {
          this.scrollTop();
          if (!this.error) {
          this.successMessage = this.$t('general.successfully_updated')
            this.$router.push({ name: "company.advertisement.list" });
          }else this.errorMessages.push(this.error)
      this.is_loading = false
      })
    },
    dropped(event) {
      let file = event.target.files[0];
      if (!file) return false;
      if (!this.compareTypeCompatibality(file, "image/*")) {
        $("#uploadFile").val("");
        this.errorMessages.push("invalid file type");
        this.scrollTop()
        return false;
      }
      this.item.photo_link = event.target.files[0];
      let blob = URL.createObjectURL(this.item.photo_link);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
      imageHolder.css("display", 'block');
      this.drop(event);
    },
    compareTypeCompatibality(file, acceptableType) {
     return acceptableType.includes(file.type) || acceptableType.includes(file.name.split('.').pop())
       || (acceptableType.split('/')[0] == file.type.split('/')[0] && file.type.split('/')[0].toLowerCase() != 'application')
    },
    validateURL(url){
     var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    },
    drag(event) {
      event.target.parentNode.className = "draging dragBox";
    },
    drop(event) {
      event.target.parentNode.className = "dragBox";
    },
    resetMessages(){
      this.successMessage = null
      this.errorMessages = []
    },
    scrollTop(){
        $("html, body").animate({ scrollTop: 0 }, "slow");
    },
  },
  mounted() {
    this.getItem()
  },
    watch: {
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
</style>